
import {
  onMounted,
  PropType,
  reactive,
  ref,
  SetupContext,
  toRaw,
  defineComponent,
  toRefs,
} from "vue";
import { CalendarOutlined, SmileOutlined } from '@ant-design/icons-vue';
import { ModifyExamDayPlanRquest, SearchExamDayPlanResponse } from "@/api-client/client";
import { validateHubCapacity } from "@/utils/validate";
import { _Client } from "@/api-client";
import { message, Form } from "ant-design-vue";
import Modify from "@/views/availabilityManagement/noneExaminingDate/modify.vue";

const useForm = Form.useForm;
export default defineComponent({
  props: {
    ExamDayPlan: {
      type: SearchExamDayPlanResponse,
      default: () => [],
    },
  },
  components: {
    CalendarOutlined,
    SmileOutlined
  },
  setup(props: { [key: string]: any }, context: SetupContext) {
    const examDayPlan: SearchExamDayPlanResponse = props.ExamDayPlan;
    const modifyLoading = ref(false);
    const request = ref<ModifyExamDayPlanRquest>(new ModifyExamDayPlanRquest());
        request.value.date = examDayPlan.date;
        request.value.eorTaskNumber = examDayPlan.eorTaskNumber;
        request.value.jpTaskNumber = examDayPlan.jpTaskNumber;
        request.value.osmTaskNumber = examDayPlan.osmTaskNumber;
        request.value.iocSpkAMTaskNumber = examDayPlan.iocSpkAMTaskNumber;
        request.value.iocSpkPMTaskNumber = examDayPlan.iocSpkPMTaskNumber;
        request.value.iopSpkAMTaskNumber = examDayPlan.iopSpkAMTaskNumber;
        request.value.iopSpkPMTaskNumber = examDayPlan.iopSpkPMTaskNumber;
    function close(refresh: boolean = false) {
      context.emit("close", refresh);
    }

    async function ModifyExamDayPlan() {
      modifyLoading.value = true;
      try {
        
        let result = await _Client.examDayPlanClient.modify(request.value);
        if (result.isSuccessfull) {
          message.success("success");
          close(true);
        }
        else
        {
          message.warning(result.message);

        }

      }
      finally {
        modifyLoading.value = false
      }
    }

    return {
      close,
      request,
      ModifyExamDayPlan,
      examDayPlan,
      modifyLoading
    };
  }
});
